html {
	height: 100%;
	font-size: $def-font-size;

	@media (max-width: $ipad) {
		font-size: 14px;
	}

	@media (max-width: $sm) {
		font-size: 12px;
	}
}

body {
	background: #fff;
	color: $text-color;
	font-family: $font-family;
	max-width: 100vw;
	height: 100%;
	line-height: normal;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
}

p {
	margin-top: 0;
	line-height: 1.5;
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	@extend %transition;
	color: $link-color;
	text-decoration: none;
	outline: none;

	&:hover {
		color: $link-color-hover;
		text-decoration: none;
		outline: none;
	}

	&:active,
	&::focus {
		outline: none;
	}
}

.page-title {
	@include fontSize(46px);
	margin-bottom: 45px;

	@media (max-width: $md) {
		@include fontSize(30px);
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $titles-color;
	margin-top: 0;
	font-weight: 700;

	&>a {
		text-decoration: none;

		&:hover {
			color: $titles-color;
		}
	}
}

h1 {
	@include fontSize(46px);
	margin-bottom: 40px;

	@media (max-width: $md) {
		@include fontSize(30px);
	}
}
.h1{
	@include fontSize(36px);
	margin-bottom: 40px;

	@media (max-width: $md) {
		@include fontSize(28px);
	}
}

h2,.h2 {
	@include fontSize(30px);
	margin-bottom: 30px;

	@media (max-width: $lg) {
		margin-bottom: 20px;
	}
}

h3,.h3 {
	@include fontSize(24px);
	margin-bottom: 25px;

	@media (max-width: $sm) {
		margin-bottom: 15px;
	}
}

h4,.h4 {
	font-size: 24px;
	margin-bottom: 25px;

	@media (max-width: $sm) {
		margin-bottom: 15px;
	}
}


::selection {
	background: $mainColor;
	/* Safari, Chrome, Opera */
	color: #fff;
}

::-moz-selection {
	background: $mainColor;
	/* FireFox */
	color: #fff;
}

.container {
	max-width: $container-width;
}

img {
	max-width: 100%;
}

.up {
	text-transform: uppercase;
}

.btn.button,
.btn,
.btn.btn-primary.btn.btn-primary {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #fff;
	@include fontSize(18px);
	font-weight: 500;
	text-decoration: none;
	height: 50px;
	padding: 0 25px;
	border-radius: 10px;
	overflow: hidden;
	border: none;
	outline: none !important;
	box-shadow: none;
	max-width: 100%;
	background: $buttonColor;
	position: relative;
	font-family: $font-family;

	&.fw {
		width: 100%;
	}

	&.btn-alt {
		background: $altColor;
		border: 1px solid #fff;
		color: $text-color;
		box-shadow: none;

		&:hover {
			background: $buttonColorHover;
		}
	}

	&.btn-min {
		height: 41px;
		padding: 0 16px;
		@include fontSize(16px);
	}

	&.btn-white {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
		box-shadow: none;
	}

	&.large {
		min-width: 230px;
	}

	&:hover,
	&:active,
	&:focus {
		background: $buttonColorHover;
		color: #fff;
	}

}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset;
}

input:not([type]),
input[type="text"],
input[type="phone"],
input[type="email"],
input[type="password"],
textarea,
.form-control {
	background: #fff;
	border: 1px solid #EDF0F8;
	padding: 15px 20px;
	color: #000;
	height: 55px;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 700;
	font-family: $font-family-alt;
	box-shadow: none;
	outline: none;
	width: 100%;
}

input:focus,
input:active,
textarea:focus,
textarea:active,
.form-control:active,
.form-control:focus {
	box-shadow: 0 0 18px rgba(201,204,221,1);
	background: #fff;
	border-color: $buttonColor;
}

textarea {
	height: 100px;
	min-height: 100px;
	resize: vertical;
	box-shadow: 0 0 18px rgba(201,204,221,0.46);
	border-radius: 10px !important;
}

select{
	background: #fff;
	border: none;
	padding: 0 20px;
	color: $text-color;
	height: 55px;
	border-radius: 10px;
	font-size: 14px;
	border: 1px solid #E8EBF4;
	outline: none;
	width: 100%;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

.form-item {
	margin-bottom: 20px;
	input, textarea{
		width: 100%;
	}
}

.form-control:-ms-input-placeholder,
.form-control:-moz-placeholder,
.form-control::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder,
input:-ms-input-placeholder {
  color: #A097AB;
  font-size: 14px;
  font-family: $font-family;
}

label {
	color: $text-color;
	font-size: 1rem;
	font-weight: 400;
}

/*checkboxes (input + label)*/
.checkbox {
	vertical-align: top;
	width: 12px;
	height: 12px;
	margin: 0;
}

.checkbox+label {
	cursor: pointer;
	display: inline-block;
}

.checkbox:not(checked) {
	position: absolute;
	opacity: 0;
}

.checkbox:not(checked)+label {
	position: relative;
	padding: 0 0 0 30px;
	font-weight: 400;
}

.checkbox:not(checked)+label:before {
	content: '';
	position: absolute;
	top: -2px;
	left: 0;
	width: 20px;
	height: 20px;
	background: #fff;
	border: 1px solid #E8EBF4;
	transition: .2s;
	border-radius: 3px;
}

.checkbox:not(checked)+label:after {
	content: '';
	position: absolute;
    top: 3px;
    left: 4px;
	width: 12px;
	height: 11px;
	background: url('../../images/check.png') no-repeat 50% 50%;
	opacity: 0;
	transition: all .2s;
}

.checkbox input:checked+label:before {
	background: #fff;
}

.checkbox:checked+label:after {
	opacity: 1;
}

/*checkboxes( input in label) */
.checkbox_inset input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 20px;
}

.checkbox_inset__text {
	position: relative;
	padding: 0 0 0 30px;
	cursor: pointer;
}

.checkbox_inset__text:before {
	content: '';
	position: absolute;
	top: -2px;
	left: 0;
	width: 20px;
	height: 20px;
	background: #CDD1DA;
	border: 2px solid #D8D8D8;
	transition: .2s;
	border-radius: 3px;
}

.checkbox_inset__text:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 10px;
	height: 8px;
	background: url('../../images/check.svg') no-repeat 50% 50%;
	background-size: contain;
	transition: .2s;
	opacity: 0;
}

.checkbox_inset input:checked+.checkbox_inset__text:before {
	background: #fff;
}

.checkbox_inset input:checked+.checkbox_inset__text:after {
	opacity: 1;
}

.radiobutton {
	vertical-align: top;
	width: 12px;
	height: 12px;
	margin: 0;
}

.radiobutton+label {
	cursor: pointer;
	display: inline-block;
}

.radiobutton:not(checked) {
	position: absolute;
	opacity: 0;
}

.radiobutton:not(checked)+label {
	position: relative;
	padding: 0 0 0 30px;
	font-weight: 400;
}

.radiobutton:not(checked)+label:before {
	content: '';
	position: absolute;
	top: 2px;
	left: 0;
	width: 18px;
	height: 18px;
	background: #E7E7E7;
	border-radius: 50%;
}

.radiobutton:not(checked)+label:after {
	content: '';
	position: absolute;
	top: 7px;
	left: 5px;
	width: 8px;
	height: 8px;
	background: #F64C4C;
	opacity: 0;
	transition: all .2s;
	border-radius: 50%;
}

.radiobutton:checked+label:after {
	opacity: 1;
}

.select2-container--default {
	outline: none;
	max-width: 100%;
	width: 100%;
	// width: 300px !important;
	background: #fff;
	height: 55px;

	// background: #fff;
	// border: none;
	// padding: 0 20px;
	// color: $text-color;
	// height: 55px;
	// border-radius: 10px;
	// font-size: 14px;
	// border: 1px solid #E8EBF4;
	// outline: none;
	// width: 100%;

	.select2-selection--single {
		border: 1px solid #E8EBF4;
		outline: none;
		background-color: #fff;
		height: 55px;
		padding-left: 20px;
	}

	.select2-selection--single .select2-selection__rendered {
		color: $text-color;
		font-size: 1rem;
		line-height: 55px;
	}

	.select2-selection--single .select2-selection__arrow b {
		position: absolute;
		right: 18px;
		top: 25px;
		width: 5px;
		left: auto;
		margin: 0;
		height: 3px;
		border: 5px solid $mainColor;
		border-left-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
	}

	.select2-dropdown {
		border: 1px solid #E8EBF4;
	}

	.select2-results__option--highlighted[aria-selected] {
		background-color: $mainColor;
		color: white;
	}
}

ul.pagination {

	justify-content: center;
	background: #F8F7F7;
	padding: 50px 0;
	position: relative;
	margin: 0 auto !important;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 9999px;
		height: 100%;
		background: #F8F7F7;
		transform: translateX(-50%);
	}

	&>li {
		position: relative;
		padding: 0 5px;

		a,
		span {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: transparent;
			color: #43425D;
			border: none;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		&.active,
		&:hover {
			color: #fff;

			a,
			span,
			.page-link {
				background-color: $buttonColor;
				color: #fff;
			}
		}

		/*&:first-child{
		  display: none;
	  }
	  &:last-child{
		  display: none;
	  }*/
	}

	.pagination-arrow {

		a,
		span {
			background-color: #E6E6E6;
		}

		&.prev {
			margin-right: 20px;

			a,
			span {
				background-image: url('../../images/icon-arrow-left-white.svg');
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}

		&.next {
			margin-left: 20px;

			a,
			span {
				background-image: url('../../images/icon-arrow-right-white.svg');
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}

	}
}

.file_inp {
	position: relative;
	overflow: hidden;
	color: #9A9A9A;
	text-align: center;
	display: inline-block;
}

.file_inp input[type="file"] {
	display: none;
}

.file_inp label {
	display: inline-flex;
	align-items: center;
	top: 0;
	left: 0;
	margin: 0;
	cursor: pointer;

	&:after {
		content: '';
		display: inline-block;
		width: 60px;
		height: 60px;
		margin-left: 30px;
		background: url('../../images/file_input.svg') no-repeat 50% 50%;
	}
}


.mb-90 {
	margin-bottom: 90px;
}

.mb-60 {
	margin-bottom: 60px;
}


.rounded {
	overflow: hidden;
	border-radius: 50% !important;
}
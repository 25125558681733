/* variables */

/* bootstrap grid size */
$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1200px;
$ipad: 1024px;

$container-width: 1300px;

/* Default font settings*/
$def-font-size: 16px;
$font-family: 'Ubuntu', sans-serif;
$font-family-alt: 'Exo 2', sans-serif;

/*main colors*/
$mainColor: #4463bb;
$altColor: #fcd462;
$buttonColor: #4463bb;
$buttonColorHover: #4e99d2;

$text-color: #392e54;
$link-color: #4463bb;
$link-color-hover: #fcd462;
$titles-color: #392e54;
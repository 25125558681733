.header{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    position: relative;
    z-index: 900;
    background: url('../../images/header-bg.png') no-repeat 100% 50%;

    @media (max-width: $ipad) {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
    }

    & > .container > .row{
        align-items: flex-end;

        @media (max-width: $ipad) {
            align-items: center;
        }
    }

    .header-content{
        display:  flex;
        align-items: center;
        padding: 48px 0;

        @media (max-width: $ipad) {
            align-items: center;
            padding: 20px 0;
        }

        @media (max-width: $md) {
            padding: 10px 0;
        }
    }


    .logo{
        @extend %flex-vcenter;
        text-align: center;
        justify-content: center;
        flex: none;
        width: 290px;
        margin-right: 45px;

        @media (max-width: $ipad) {
            height: 50px;
            margin-right: auto;
            max-width: 50%;
        }
        
    }

    .header-number-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 45px;

        @media (max-width: $ipad) {
            margin-left: auto;
            display: none;
        }

        &__icon{
            margin-right: 18px;
        }
        &__value{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            border-radius: 38px;
            height: 38px;
            background: $altColor;
            font-weight: 700;
            font-size: 26px;
            margin-right: 10px;
        }
        &__text{
            color: #aca9b4;
            font-size: 12px;
        }
    }

    .header-city-select{
        height: 40px;
        width: 190px;
        border-radius: 40px;
        border: 1px solid #63C1EE;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
        position: relative;
        margin-right: 45px;

        @media (max-width: $ipad) {
            display: none;
            margin-left: auto;
        }

        &__value{
            font-size: 14px;
        }

        &:before,&:after{
            display: block;
            content: '';
        }

        &:before{
            position: absolute;
            left: 30px;
            top: 12px;
            width: 15px;
            height: 14px;
            background: url('../../images/city-select-icon.png') no-repeat 50% 50%;
        }

        &:after{
            position: absolute;
            right: 30px;
            top: 17px;
            width: 5px;
            height: 3px;
            border: 5px solid $mainColor;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        
    }

    .head-search-icon{
        @media (max-width: $ipad) {
            margin-left: auto;
        }
    }

}

.main-page{
    .header{
        background: none;
        @media (max-width: $ipad) {
            background: #fff;
        }
    }
}
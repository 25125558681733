.loan-item{
    position: relative;
    border: 1px solid #E8EBF4;
    border-radius: 15px;
    padding: 50px 30px 30px;
    margin-bottom: 30px;
    @extend %transition;

    &:hover{
        box-shadow: 0 0 32px rgba(149,190,235,0.45);
    }
    
    &__image{
        border: 1px solid #E8EBF4;
        border-radius: 15px;
        padding: 15px;
        margin-bottom: 30px;
        text-align: center;

        img{
            width: 185px;
            height: 125px;
            object-fit: contain;
        }
    }

    &__reviews-min{
        text-align: center;

        .stars{
            justify-content: center;
            margin-bottom: 18px;
        }
    }

    &__reviews-min-count{
        font-size: 14px;
        color: $mainColor;
    }

    &__min-text{
        font-size: 14px;
        margin-bottom: 25px;
    }

    &__company-link{
        text-decoration: dashed;
        border-bottom: 1px dashed $mainColor;
        @extend %transition;

        &:hover{
            border-bottom-color: transparent;
        }
    }

    &__props{
        margin: 0 0 30px 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li{
            list-style: none;
            margin-bottom: 15px;
            color: #878194;
            font-size: 14px;
            padding-left: 25px;
            position: relative;
            width: 45%;

            &:before{
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                display: block;
                width: 11px;
                height: 9px;
                background: url('../../images/props_check.png') no-repeat 50% 50%;
            }
        }
    }

    .loan-item-main-props{
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #E8EBF4;
        margin-bottom: 30px;

        .loan-item-main-props-item{
            margin-bottom: 25px;
            margin-right: 55px;

            &__title{
                font-size: 14px;
                color: #AFABB6;
                margin-bottom: 10px;
            }

            &__value{
                font-size: 18px;
                font-weight: 700;
                font-family: $font-family-alt;
            }

            &.price-property{
                &__value{
                    color: #4e99d2;
                }
            }
        }

        
    }
    
}

.loan-item-approval{
    text-align: center;
    margin-bottom: 20px;

    &__text{
        color: #4a5f80;
        font-size: 12px;
        margin-bottom: 10px;
    }

    &__value{
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 15px;
        font-family: $font-family-alt;
    }

    &__visual{
        height: 6px;
        width: 100%;
        background: #E7EBEF;
        border-radius: 3px;
        overflow: hidden;
        position: relative;

        &-progress{
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            background: #00C62C;
        }
    }

}


.loan-min-view{
    padding: 30px 0;
    position: relative;
    border: 1px solid #E8EBF4;
    border-radius: 15px;
    margin-bottom: 50px;

    &__image{
        text-align: center;
        margin-bottom: 20px;
        img{
            height: 120px;
            object-fit: none;
            display: inline-block;
        }
    }

    &__title{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 25px;
    }

    &__properties{
        background: #EEF6FC;
        padding: 15px;
        margin-bottom: 25px;

        table{
            width: 220px;
            margin: 0 auto;

            td{
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .loan-min-view__propertie-title{
            color: #A4A4B5;
            font-size: 14px;
            padding-right: 10px;
        }

        .loan-min-view__propertie-value{
            font-family: $font-family-alt;
            color: #3A2A54;
            font-size: 16px;
            font-weight: 700;

            &.price-property{
                color: #009FD7;
            }
        }
    }

    &__text{
        line-height: 1.5;
        margin-bottom: 25px;
        padding: 0 20px;
        text-align: center;
        font-size: 14px;
    }
}


.loan-items-list{
    .loan-item{
        margin-bottom: 50px;
    }
}

.swiper-slide{
    .loan-min-view{
        margin-bottom: 0;

        &__title{
            text-align: left;
        }

        &__text{
            text-align: left;
        }

        &__link{
            width: calc(100% - 40px);
        }
    }
}
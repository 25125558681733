.filter{

}

.filter-criteria{
    margin-bottom: 50px;

    &__title{
        margin-bottom: 15px;
        font-size: 14px;
        color: #736A88;
    }
}

.filter-slider{
    &__visual-input{
        position: relative;
        background: #fff;
        height: 55px;
        margin-bottom: -40px;
        border-radius: 10px;
    }

    &__value{
        position: absolute;
        top: 13px;
        left: 30px;
        font-size: 20px;
        font-weight: 600;
    }

    &__text{
        position: absolute;
        top: 13px;
        right: 30px;
        font-size: 20px;
        font-weight: 600;
    }
}

.irs--round .irs-min, .irs--round .irs-max{
    font-size: 11px;
    color: #A9AABA;
    background: transparent;
    padding: 0;
    top: 50px;
    visibility: visible !important;
}

.irs--round .irs-line {
    background-color: #E7EBEF;
}

.irs--round .irs-bar {
    background-color: $mainColor;
}

.irs--round .irs-handle {
    border: 4px solid #fff;
    background: $mainColor;

    &:hover{
        border: 4px solid #fff;
        background: $altColor;
        cursor: pointer;
    }
}

.irs-single{
    display: none;
}
.questions{
    background: #EEF6FC url('../../images/questions_bg.png') no-repeat 50% 50%;
    background-size: contain;
    padding: 60px;

    @media (max-width: $md){
        padding: 60px 0px;
    }

    h2{
        margin-bottom: 40px;
    }
}

.questions-list{
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
}

.question-item{
    margin-bottom: 20px;
    padding: 20px 40px;
    border-radius: 15px;
    background: #fff;
    position: relative;
    min-height: 60px;
    cursor: pointer;

    @media (max-width: $md){
        padding: 15px 15px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &:after{
        content: '';
        position: absolute;
        top: 19px;
        right: 25px;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #E7EBEF;
        background-image: url('../../images/q-arr.png');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        @extend %transition;
    }

    &__title{
        font-size: 22px;
        margin-bottom: 0;

        @media (max-width: $md){
            font-size: 15px;
            font-weight: 500;
        }
    }

    &__content{
        margin-top: 25px;
        font-size: 16px;
        line-height: 1.7;
        display: none;

        @media (max-width: $md){
            font-size: 12px;
        }
    }

    &.active{
        &:after{
            background-color: #009AD5;
            transform: rotate(180deg);
        }
    }
}
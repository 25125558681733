.calc{
    border: 2px solid #FFD559;
    border-radius: 15px;
    padding: 45px;

    @media (max-width: $md){
        padding: 20px;
    }

    .calc-input{
        margin-bottom: 20px;

        label{
            font-size: 14px;
            color: #878194;
            margin-bottom: 10px;
        }
    }
}

.calc-result{
    background: #F3F4F6;
    padding: 35px;
    border-radius: 15px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $md){
        padding: 15px;
    }

    &:after{
        content: '';
        display: block;
        height: 8px;
        width: calc(100% - 30px);
        position: absolute;
        bottom: 0;
        left: 15px;
        background: url('../../images/calc-result-bg.png') no-repeat ;
        background-size: cover;
    }

    table{
        margin: 0 auto;

        td{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__propertie-title{
        color: #A4A4B5;
        font-size: 14px;
        padding-right: 10px;

        @media (max-width: $md){
            font-size: 12px;
        }
    }

    &__propertie-value{
        font-family: $font-family-alt;
        color: #3A2A54;
        font-size: 16px;
        font-weight: 700;

        @media (max-width: $md){
            font-size: 12px;
        }

        &.price-property{
            color: #009FD7;
        }
    }
}
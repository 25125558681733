/* import styles */
@import "vars";
@import "mixins";
@import "normalize";
@import "base";
@import "./header/all";
@import "./content/all";


/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
    .content,
    .sidebar,
    .right-sidebar{
		max-width: $container-width;
		margin: 0 auto;
    }
}
/*--------------------------------------------------------------
//If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/

/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/

@supports (display: grid) {


    @media screen and (min-width: 800px) {
    
        .site-container{
            display: grid;
            min-height: 100%;
            // 12 column layout
            grid-template-columns: repeat(12, 1fr);
            // header and footer height, content-height responsive
            grid-template-rows: auto auto;
            grid-template-areas: 
                // "h h h h h h h h h h h h"
                "c c c c c c c c c c c c"
                "r r r r r r r r r r r r"
                // "f f f f f f f f f f f f"
                ;
        }

        
        .content{
            grid-area: c;
        }
        
        .sidebar{
            grid-area: l;
        }
        
        .right-sidebar{
            grid-area: r;
            
        }
        

    }
  
    @media screen and (min-width: 1200px) {
        .site-container{
            // 12 колоночный макет
            grid-gap: 70px;
            grid-template-columns: repeat(12, 1fr);
            // высота шапки и футера, котент резиновый
            grid-template-rows: auto;
            grid-template-areas: 
                // "h h h h h h h h h h h h"
                "c c c c c c c c r r r r"
                // "f f f f f f f f f f f f"
                ;
        }

    }

    .site-container{
        max-width: $container-width;
        padding: 0 15px;
        margin: 70px auto;
    }
}

/*--------------------------------------------------------------
// CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/
@media screen and (max-width: 1200px) {

    .content{
        margin-bottom: 50px;
    }

    .right-sidebar{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        & > *{
            flex: 1 0 300px;
            margin: 0 15px 50px 15px;
        }
    }

}

@media screen and (max-width: $ipad) {

    .site-container{
        margin-top: 125px;
    }

}

@media screen and (max-width: $md) {

    .site-container{
        margin-top: 120px;
    }

}


.section{
    margin-bottom: 50px;
}


footer.footer{
    padding: 50px 0;
    background: #EEF6FC;

    .footer-logo{
        width: 224px;
        margin-bottom: 12px;
    }
    .footer-copyright{
        font-size: 12px;
        color: #807D93;
        padding-left: 60px;
    }

    .footer-menu{
        a{
            display: block;
            font-size: 14px;
            margin-bottom: 12px;
            font-weight: 400;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .site-support{
        @media (max-width: $md){
            margin-top: 30px;
        }

        &__title{
            font-size: 13px;
            color: #A4A5B5;
            margin-bottom: 10px;
            font-weight: 500;
        }
        &__email{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            display: inline-block;
        }
        &__caption{
            font-size: 12px;
            color: #A4A5B5;
        }

    }
 
    .footer-divider{
        border-bottom: 1px solid #E3ECF2;
        margin-bottom: 45px;
    }

    p{
        font-size: 14px;
        color: #A4A5B5;

        @media (max-width: $md){
            font-size: 12px;
        }
    }
}


.breadcrumbs{
    padding: 0;
    margin-bottom: getRem(24px);
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: $ipad){
        margin-bottom: 20px;
    }

    .breadcrumbs-icon{
        width: 11px;
        height: 9px;
        display: inline-block;
        margin-right: 20px;
        background: url('../../images/home.png');
        margin-bottom: 10px;
    }

    a{
        color: $mainColor;
        display: inline-block;
        font-size: 14px;
        margin-right: 10px;
        margin-bottom: 10px;
        text-decoration: none;

        &:after{
            content: '';
            display: inline-block;
            margin-left: 10px;
            width: 3px;
            height: 5px;
            border: 5px solid $mainColor;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    span{
        font-size: 14px;
        color: $mainColor;
        text-decoration: none;
        margin-bottom: 10px;
    }
}


.bang{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: $altColor;
    font-size: 12px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px;
    border-radius: 0 0 5px 5px;
    white-space: nowrap;
}



.subject-card{
    padding: 25px 40px;
    background: #EEF6FC;
    border-radius: 15px;
    margin-bottom: 50px;

    ul{
        margin: 0;
        padding: 0;
        ul{
            margin-top: 10px;
        }

        li{
            list-style: none;
            position: relative;
            padding-left: 25px;
            margin-bottom: 15px;
            
            &:before{
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                display: block;
                border-radius: 50%;
                margin-right: 20px;
                width: 8px;
                height: 8px;
                background: $mainColor;
            }

            a{
                font-size: 15px;
            }
        }
    }
}

.figure{
    margin: 0;
    border-radius: 10px;
    overflow: hidden;

    img{
        margin-bottom: 0;
    }

    .figure-caption{
        text-align: center;
        padding: 15px 20px 20px 20px;
        font-size: 14px;
        background: #EEF6FC;
    }
}

.quote{
    display: flex;
    margin-bottom: 40px;
    img{
        flex: none;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 30px;
    }

    .quote-text{
        background: #EEF6FC;
        padding: 25px 30px;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        line-height: 1.5;
        
        &:before{
            content: '';
            position: absolute;
            display: block;
            height: 20px;
            width: 20px;
            left: -20px;
            top: 20px;
            border: 10px solid transparent;
            border-right-color: #EEF6FC;
        }
    }

    .quote-caption{
        font-size: 12px;

    }
}


blockquote{
    border: 2px solid #719BEC;
    border-radius: 10px;
    padding: 15px 30px;
    display: flex;
    margin-bottom: 30px;
    line-height: 1.5;

    img{
        display: inline-block;
        margin-right: 30px;
        align-self: flex-start;
    }

    &.var2{
        border-color: #64DB78;
    }

    &.var3{
        border-color: #FFD559;
    }
}


ul.styled{
    margin: 0 0 25px 0;
    padding: 0 0 0 10px;

    li{
        list-style: none;
        display: block;
        position: relative;
        margin-bottom: 15px;
        padding-left: 30px;

        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 11px;
            height: 12px;
            background: url('../../images/li-list.png') no-repeat 50% 50%;
        }
    }

}


ol{
    counter-reset: myCounter;
    margin: 0 0 35px 0;
    padding: 0 0 0 10px;

    li{
        list-style: none;
        display: block;
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &:before{
            counter-increment: myCounter;
            content:counter(myCounter);
            color: white;
            background: $mainColor;
            display: inline-block;
            text-align: center;
            line-height: 23px;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            font-size: 14px;
            position: absolute;
            top:-1px;
            left: 0;
            font-weight: 500;

        }
    }

}


table.table{
    font-size: 14px;

    thead{
        tr{
            background: $mainColor;
            color: #fff;

            &:first-child{
                td{
                    padding: 12px 30px;
                    text-align: center;
                    border: none;
                
                    &:first-child{
                        border-radius: 10px 0 0 0;
                        text-align: left;
                    }

                    &:last-child{
                        border-radius: 0 10px 0 0;
                    }

                }
            }
        }
    }
    
    tbody{
        tr{
            &:nth-child(odd){
                background: #EEF6FC;
            }

            td{
                padding: 12px 30px;
                text-align: center;
                border: none;

                &:first-child{
                    text-align: left;
                }
            }
        }
    }
}


.sidebar-block{
    padding: 30px 35px;
    border-radius: 15px;
    margin-bottom: 50px;
    border: 1px solid #E8EBF4;

    &.gray{
        background: #EEF6FC;
        border: none;
    }

    &__title{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
    }
}

.tags-list{
    .tags-list-item{
        background: #fff;
        font-size: 14px;
        padding: 8px 17px;
        display: inline-block;
        border-radius: 35px;
        text-align: center;
        margin: 0 10px 10px 0;
        color: $text-color;
        @extend %transition;

        &:hover{
            background: $mainColor;
            color: #fff;
        }

        &:last-child{
            margin: 0;
        }
    }
}



.pagination{
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__item{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px 10px 5px;
        width: 35px;
        height: 35px;
        background: #EEF6FC;
        border-radius: 50%;
        color: #009AD5;
        @extend %transition;
        font-weight: 500;

        &_active, &:hover{
            color: #fff;
            background: $mainColor;
        }
    }

   
}


.main-page-header{
    position: relative;
    margin-bottom: 50px;
    padding-top: 270px;
    margin-top: -210px;
    background: url('/images/main-page-bg.png') no-repeat 50% 50%;
    background-size: cover;

    @media (max-width: $ipad){
        padding-top: 0;
        margin-top: 110px;
    }


    &__description{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 50px;
    }

    &:after{
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 50%;
        background: url('/images/header-woman-main.png') no-repeat 100% 0;
        background-size: contain;

        @media (max-width: 1600px){
            width: 30%;
            background: url(/images/header-woman-main.png) no-repeat 100% 100%;
            background-size: contain;
        }
        @media (max-width: $ipad){
           display: none;
        }
    }

    select,
    .select2-selection--single .select2-selection__rendered{
        font-size: 18px;
        font-weight: 700;
    }
}

.main-page-sidebar{

    @media (max-width: $xl){
        display: flex;
        flex-wrap: wrap;
        & > *{
            flex: 1 0 300px;
            margin: 0 15px 50px 15px;
        }
    }
    
}

.msearch-form{
    border-bottom: 1px solid #D7ECF7;
    padding-bottom: 30px;
    position: relative;
    z-index: 10;

    .btn{
        height: 55px;
    }
}


.ionTabs__head{
    background: #EEF6FC;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 50px;
    
    @media (max-width: $sm){
        display: flex;
        flex-wrap: wrap;
    }

}

.ionTabs__tab{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 0 20px;
    font-weight: 500;
    color: $text-color;
    @extend %transition;

    @media (max-width: $sm){
        flex: 1 0 auto;
        font-size: 11px;
        padding: 0 5px;
    }
    
    &.ionTabs__tab_state_active, &:hover{
        background: #FFD559;
    }
}


.slider-container{
    position: relative;
    display: block;
}

.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 22px;
    height: 72px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../images/slider-arrow.png');
    outline: none;
    @extend %transition;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    @media (min-width: 1400px){
        right: -45px;
    }
    @media (min-width: 1500px){
        right: -55px;
    }
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    transform: translateY(-50%) rotate(180deg);
    left: 10px;
    right: auto;
    @media (min-width: 1500px){
        left: -55px;
    }
}


    .main-menu{
        border-radius: 15px;
        background: $mainColor;
        overflow: hidden;
        height: 65px;

        @media (max-width: $ipad) {

        }

        &>ul{
            padding: 0;
            margin: 0;
            height: 100%;
            display: flex;
            align-items: stretch;
        }

        li{
            list-style: none;
        }

        a{
            font-size: 1rem;
            font-weight: 500;
            color: #fff;
            text-decoration: none;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 22px;
            height: 100%;
            @extend %transition;
            
            &:hover, &.active{
                color: #fff;
                background: #009AD5;
            }
        }

        li:first-child{
            a{
                padding: 0 22px 0 38px;
            }
        }
        li:last-child{
            a{
                padding: 0 38px 0 22px;
            }
        }

        .header-city-select{
            display: none;
        }

        @media (max-width: $ipad) {

            a{
                padding: 0 15px;
            }

            li:first-child{
                a{
                    padding: 0 15px;
                }
            }
            li:last-child{
                a{
                    padding: 0 15px;
                }
            }
        }


        @media (max-width: $ipad) {
            
            margin-top: 70px;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            right: 100%;
            top: 0;
            width: 100%;
            height: calc( 100vh - 70px);
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            overflow-y: auto;
            z-index: 999;
            background: $mainColor;
            border-top: 1px solid #3E3E3E;
            border-radius:0;
            transition: 0.25s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
            padding: 30px 0;

            
            &.active {
                right: 0;
            }

            & > ul{
                display: block;
                text-align: center;
                height: auto;
                margin-bottom: 30px;
            }
            
            ul li{
                display: block;
                padding: 0;
            }

            ul a{
                display: block;
                padding: 15px 30px;
                color: #fff;
                line-height: 100% !important;

                &:hover{
                    background: $buttonColorHover;
                    color: #fff !important;
                }
                
            }

            & li li {
                padding-left: 20px;
            }
            
            &>li.divider {
                position: relative;
                width: 100%;
                height: 1px;
                background: #697780;
            }
            & li>a {
                font-size: 14px;
                text-transform: uppercase;
                padding: 12px 20px !important;
            }
            & li ul {
                border-top: none;
                position: relative;
                top: 0;
                padding-left: 10px;
                display: none;
                width: 100%;
                border-radius: 0;
                box-shadow: none;
                border-top: none;
            }
            & li:hover>ul {
                display: none;
            }
            & li li ul {
                left: 0;
                top: 0;
                border-top: none;
                border-left: 2px solid #FF4033;
            }
            &>li:hover>a,
            &>li.selected>a {
                color: #fff;
                background: #FF4033;
            }

            ul ul{
                transition: 0.25s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
                width: 100%;
                max-width: 100%;
                height: 0;
                overflow: hidden;
                display: block;
            }

            ul li:hover>ul{
                height: auto;
            }

            ul ul a {
                padding: 15px 30px;
                margin: 0;
            }

            .header-city-select{
                display: flex;
                margin-top: auto;
                margin: auto auto 0 auto;
                flex: none;
            }
            
        }

        
    }

.toggle-button {
    margin-left: 30px;
    position: relative;
    z-index: 1000;
    display: none;

    @media (max-width: $ipad) {
        display: block;
    }
}

.hamburger {
    width: 30px;
    display: block;
    cursor: pointer;

    span {
        display: block;
        width: 100%;
        border-bottom: 2px solid $mainColor;
        position: relative;
        -webkit-transition: all 0.4s step-end;
        transition: all 0.4s step-end;

        &:before,
        &:after {
            width: 100%;
            content: '';
            display: block;
            margin-bottom: 7px;
            -webkit-transition: all 0.4s linear;
            transition: all 0.4s linear;
        }

        &:before {
            border-top: 2px solid $mainColor;
        }
        &:after {
            border-bottom: 2px solid $mainColor;
        }
    }

    &.active span {
        border-bottom: none;

        &:before,
        &:after {
            margin-bottom: 0px;
            position: absolute;
        }

        &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }

    &.white {
        span{
            border-bottom-color: #fff;
            &:before {
                border-top-color: #fff;
            }
            &:after {
                border-bottom-color: #fff;
            }
        }
    }


}

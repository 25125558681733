@function getRem($size) {
    $remSize: $size / $def-font-size;
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: getRem($size);
}
// использование @include fontSize(32px);



%flex-vcenter {
    display: flex;
    align-items: center;
}

%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%pseudo{
    content: '';
    display: block;
}

%transition{
    transition: 0.25s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

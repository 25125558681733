.list-item{
    display: block;
    border: 1px solid #E8EBF4;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    @extend %transition;
    color: $text-color;
    margin-bottom: 60px;

    &:hover{
        transform: scale(1.03);
    }

    img{
        display: inline-block;
        width: 100%;
        height: 230px;
        object-fit: cover;
    }

    &__content{
        padding: 25px 25px;
    }

    &__title{
        color: $text-color;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 1px solid #E8EBF4;
        padding-bottom: 30px;
        margin-bottom: 20px;
        line-height: normal;
    }

    &__date{
        color: #AFAAB6;
        font-size: 14px;
    }

    &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__category{
        font-size: 14px;
        color: $mainColor;
    }
}

.swiper-slide{
    .list-item{
        margin-bottom: 0;
    }
}
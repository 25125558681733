.review-item{
    position: relative;
    margin-bottom: 50px;

    .review-item-content{
        padding-left: 100px;
    }

    .review-item-header{
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    &__icon{
        position: absolute;
        top: 0;
        left: 0;

        img{
            display: inline-block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
        }
    }

    &__name{
        color: #4463bb;
        font-size: 13px;
        margin-right: 35px;
    }

    &__answer{
        color: $text-color;
        background: #FFD559;
        height: 20px;
        padding: 0 10px;
        font-size: 12px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__date{
        font-size: 12px;
        color: #878194;
    }

    .stars{
        margin-right: 30px;
    }

    .review-item-info{
        &__title{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;

            &.green{
                color: #52b037;
            }

            &.red{
                color: #d33b54;
            }

            &.blue{
                color: #4463bb;
            }
        }

        &__text{
            margin-bottom: 15px;
            line-height: 1.5;
        }

        .bottom_line{
            border-bottom: 1px solid #F2F2F2;
            margin-bottom: 25px;
            padding-bottom: 10px;
        }
    }

}

@media (max-width: $md){
    .review-item{
        .review-item-content{
            padding-left: 0;
        }
    
        .review-item-header{
            display: block;
            padding-left: 85px;

            & > *{
                margin-bottom: 10px;
            }
        }
    
    }
}

.stars{
    display: flex;
    align-items: center;

    .star{
        width: 14px;
        height: 13px;
        margin-right: 4px;
        background: url('../../images/star.png');

        &.active{
            background: url('../../images/star-active.png');
        }
    }
}

.review-form{
    background: #EEF6FC;
    border-radius: 15px;
    padding: 30px;
    position: relative;
    
    &__header{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__title{
        font-weight: 500;
        margin-right: 30px;
    }

    &__content{
        input{
            background: #fff;
            border: none;
            padding: 15px 20px;
            color: #3A2A54;
            height: 40px;
            border-radius: 10px;
            font-size: 14px;
            box-shadow: 0 0 18px rgba(201,204,221,0.46);
            outline: none;
            font-family: $font-family;
            font-weight: 500;
        }
        textarea{
            height: 100px;
            min-height: 100px;
            resize: vertical;
            box-shadow: 0 0 18px rgba(201,204,221,0.46);
            border-radius: 10px !important;
            font-family: $font-family;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .stars{
        z-index: 5;
    }
    .star{
        position: relative;
        cursor: pointer;
        margin-right: 10px;

        &:after{
            content: '';
            display: block;
            width: 29px;
            height: 29px;
            background: #FFD559;
            position: absolute;
            border-radius: 50%;
            top: -7px;
            left: -7px;
            opacity: 0;
            z-index: -1;
            @extend %transition;
        }

        &:hover{
            background: url('../../images/star-hover.png');
            &:after{
                opacity: 1;
            }
        }
    }

    &__textarea{
        position: relative;

        textarea{
            padding-top: 35px;
        }
    }

    &__textarea-title{
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        left: 20px;
        top: 10px;

        &.green{
            color: #52b037;
        }

        &.red{
            color: #d33b54;
        }
    }
}